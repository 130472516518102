var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.$apollo.loading || this.$store.getters.GET_ID_DESIGNATION_COMITE == null
    )?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"success"}})],1):_c('div',[_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"m-2",attrs:{"title":"Estado Revision","left":"","text":_vm.nombreEstadoRevision == null
          ? 'Estado Revisión'
          : _vm.nombreEstadoRevision,"variant":"primary"}},_vm._l((_vm.ESTADOS_REVISION),function(nombre,sigla){return _c('b-dropdown-item',{key:nombre,on:{"click":function($event){return _vm.selecEstado(sigla)}}},[_vm._v(_vm._s(nombre))])}),1),_vm._l((_vm.misDesRevision),function(val,index){return _c('b-card',{key:index,staticClass:"my-3 mis-card"},[_c('b-row',[_c('div',{staticClass:"col-lg-4"},[(
              val.postulacion.ultimaVersionDoc &&
              val.postulacion.ultimaVersionDoc.thumbnail
            )?_c('b-card-img',{staticClass:"rounded-0",attrs:{"src":_vm.URL_FILES + val.postulacion.ultimaVersionDoc.thumbnail,"alt":"Thumbnail"}}):_c('img',{staticClass:"rounded-0 card-img",attrs:{"src":require("@/assets/img/default_doc.png"),"alt":"Thumbnail"}})],1),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"titulo2 mb-2",staticStyle:{"color":"#e23707"}},[_vm._v(" "+_vm._s(val.postulacion.titulo)+" ")]),_c('span',{staticClass:"texto-nombre"},[_vm._v(" "+_vm._s(val.postulacion.participante.nombres + " " + val.postulacion.participante.apellidos)+" ")]),_c('div',[_c('b-badge',{class:_vm.asBadgeClass(val.postulacion.etapaRevision)},[_vm._v(_vm._s(_vm.ETAPAS_REVISION[val.postulacion.etapaRevision]))]),_c('b-badge',{class:_vm.asBadgeClass(val.postulacion.estadoRevision)},[_vm._v(_vm._s(_vm.ESTADOS_REVISION[val.postulacion.estadoRevision]))])],1),_c('span',{staticClass:"texto-cuerpo"},[_c('b',[_vm._v("Categoria: ")]),_vm._v(" "+_vm._s(val.postulacion.categoria.nombre)+" ")]),_c('br'),_c('div',{staticClass:"mt-3 text-center"},[_c('router-link',{attrs:{"to":{
                name: 'observaciones',
                params: { idDesignacionRevision: val.id },
              }}},[_c('b-button',{staticClass:"btn-revisar",attrs:{"size":"sm"}},[_c('span',[_vm._v("Revisar")])])],1)],1)])])],1)})],2),_c('b-modal',{attrs:{"centered":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',[_vm._v("Información Importante")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"row"},[_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" Cancelar ")])],1)],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('p',{staticClass:"my-4",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" El estado Seleccionado no cuenta con datos actualmente. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }