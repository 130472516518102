var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$apollo.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"success"}})],1):(_vm.error)?_c('div',{staticClass:"text-center"},[_c('span',[_vm._v("Error, verifique su conexión y recargue la página")])]):_c('div',[_c('div',{staticClass:"home-app"},[_c('AppSideBarVue'),_c('div',{staticClass:"container-section"},[_c('AppHeaderVue'),(
          _vm.$route.name == 'inicio' &&
          this.$store.getters.GET_LEVEL_USER == 'EVL'
        )?_c('div',[_c('InicioIndex')],1):_vm._e(),(
          _vm.$route.name == 'inicio' &&
          this.$store.getters.GET_LEVEL_USER != 'EVL'
        )?_c('div',[_c('InicioSoporte')],1):_c('router-view')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }